import { getEnvironment } from './utility';

const environment = getEnvironment();
const isDevelopment = environment === 'development';

const mainDomain = isDevelopment ? 'localhost' : '.childrensmiraclenetworkhospitals.org';
const wordpressSiteEnvironments = {
  development: 'https://staging.childrensmiraclenetworkhospitals.org/',
  staging: 'https://staging.childrensmiraclenetworkhospitals.org/',
  test: 'https://staging.childrensmiraclenetworkhospitals.org/',
  production: 'https://childrensmiraclenetworkhospitals.org/'
};

const wordpressApiEndPoint = wordpressSiteEnvironments[getEnvironment() as keyof typeof wordpressSiteEnvironments];

/**
 * All the constants for the site.
 */
export const globalDef = {
  mainDomain,
  wordpressApiEndPoint,
};
